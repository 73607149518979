import React, { useEffect } from 'react';
import { collection, addDoc } from 'firebase/firestore';
import { db } from '../../firebase';
import { Webchat, WebchatProvider, getClient } from '@botpress/webchat';
import { v4 as uuidv4 } from 'uuid';
import OffcanvasExample from "../Main/NavBar";
import '../../styles/ChatPage.css';

const clientId = "3ad3e1b6-ab58-4503-a8ba-ad18bf3d1319";

const Chatbot = ({ toggleDarkMode, toggleDarkTheme, messageId, conversationId }) => {
    const client = getClient({ clientId });

    const configuration = {
        color: '#044FB0',
        botName: "LibyaShop",
        clientId: "3ad3e1b6-ab58-4503-a8ba-ad18bf3d1319",
        hostUrl: 'https://webchat.botpress.cloud',
        avatarUrl: "https://files.bpcontent.cloud/2025/03/04/20/20250304204100-W3W60U1N.jpeg",
        phoneNumber: "091-898-7171",
        termsConditions: "https://libyashop.ly/terms",
        privacyPolicy: "https://libyashop.ly/",
        emailAddress: "@support.com",
        website: "https://libyashop.ly/",
        useSessionStorage: true,
        showBotInfoPage: true,
        enableConversationDeletion: true,
        showPoweredBy: false,
        theme: toggleDarkMode ? 'dark' : 'light',
        themeColor: toggleDarkMode ? '#1a1a1a' : '#ffffff',
        hideWidget: true,
        disableAnimations: true,
    };

    useEffect(() => {
        const inputElement = document.querySelector('.bpComposerInput');
        if (inputElement) {
          inputElement.setAttribute('placeholder', 'اكتب هنا ...');
          inputElement.style.direction = 'rtl';
          inputElement.style.textAlign = 'right';
        }
      }, []);

    // GET A CONVERSATION
    const fetchConversation = async () => {
        const url = 'https://api.botpress.cloud/v1/chat/conversations';
        const headers = {
            'x-integration-id': 'intver_01JBVV0VQW8XN3Z5V9K8DB1M3B',
            'x-bot-id': '9f2c204a-f378-4305-a643-279ca728450f',
            'Authorization': 'Bearer bp_pat_pgU4lVEDC6NM5SWCSZOJq1WIzgdHxQ8VC1Yj',
        };

        try {
            const response = await fetch(url, { headers });
            const data = await response.json();
            // console.log('API Response:', JSON.stringify(data, null, 2)); 

            if (data.conversations && Array.isArray(data.conversations)) {
                // console.log('Conversations:', JSON.stringify(data.conversations, null, 2));

                for (const conversation of data.conversations) {
                    await fetchMessages(conversation.id);
                }
            } else {
                console.error('No conversations found in the response or data is not an array');
            }
        } catch (error) {
            console.error('Error fetching conversations:', error);
        }
    };

    // GET ALL Conversations
    const fetchConversations = async () => {
        const url = 'https://api.botpress.cloud/v1/chat/conversations';
        const headers = {
            'x-integration-id': 'intver_01JBVV0VQW8XN3Z5V9K8DB1M3B',
            'x-bot-id': '9f2c204a-f378-4305-a643-279ca728450f',
            'Authorization': 'Bearer bp_pat_pgU4lVEDC6NM5SWCSZOJq1WIzgdHxQ8VC1Yj',
        };

        try {
            const response = await fetch(url, { headers });
            const data = await response.json();
            console.log('API Response:', JSON.stringify(data, null, 2)); 

            if (data.conversations && Array.isArray(data.conversations)) {
                // console.log('Conversations:', JSON.stringify(data.conversations, null, 2));

                for (const conversation of data.conversations) {
                    await fetchMessages(conversation.id);
                }
            } else {
                console.error('No conversations found in the response or data is not an array');
            }
        } catch (error) {
            console.error('Error fetching conversations:', error);
        }
    };

    // GET A MESSAGE 
    const fetchMessage = async (messageId) => {
        const url = `https://api.botpress.cloud/v1/chat/messages/${messageId}`;
        const headers = {
            'x-integration-id': 'intver_01JBVV0VQW8XN3Z5V9K8DB1M3B',
            'x-bot-id': '9f2c204a-f378-4305-a643-279ca728450f',
            'Authorization': 'Bearer bp_pat_pgU4lVEDC6NM5SWCSZOJq1WIzgdHxQ8VC1Yj',
        };

        try {
            const response = await fetch(url, { headers });
            const data = await response.json();
            // console.log(`Message for conversation ${messageId}:`, JSON.stringify(data.messages, null, 2));


            for (const message of data.messages) {
                await saveMessageToFirebase(messageId, message);
            }
        } catch (error) {
            console.error(`Error fetching messages for conversation ${conversationId}:`, error);
        }
    };


    // get all messages 
    const fetchMessages = async (conversationId) => {
        const url = `https://api.botpress.cloud/v1/chat/messages`;
        const headers = {
            'x-integration-id': 'intver_01JBVV0VQW8XN3Z5V9K8DB1M3B',
            'x-bot-id': '9f2c204a-f378-4305-a643-279ca728450f',
            'Authorization': 'Bearer bp_pat_pgU4lVEDC6NM5SWCSZOJq1WIzgdHxQ8VC1Yj',
        };

        try {
            const response = await fetch(url, { headers });
            const data = await response.json();
            // console.log(`Messages for conversation ${conversationId}:`, JSON.stringify(data.messages, null, 2));


            for (const message of data.messages) {
                await saveMessageToFirebase(conversationId, message);
            }
        } catch (error) {
            console.error(`Error fetching messages for conversation ${conversationId}:`, error);
        }
    };


    const saveMessageToFirebase = async (conversationId, messageId) => {
        // Keywords related to complaints
        const complaintKeywords = [
            'complain', 'issue', 'problem', 'error', 'bad', 'not working', 'poor',
            'مشكلة', 'حذف', 'شكوي', 'لا يعمل', 'سئ', 'خطأ'
        ];

        // Check if the message text contains any of the complaint keywords
        const isComplaint = complaintKeywords.some(keyword =>
            messageId.payload.text.toLowerCase().includes(keyword)
        );

        console.log(`Is complaint: ${isComplaint}`);

        // Save the message only if it is a complaint
        if (isComplaint) {
            try {
                await addDoc(collection(db, 'Chatbot_Messages22'), {
                    conversationId: conversationId,
                    messageId: messageId, // Use message.id instead of messageId
                    text: messageId.payload.text,
                    timestamp: messageId.createdAt,
                    direction: messageId.direction,
                    userId: messageId.userId,
                    type: messageId.type,
                });

                console.log(`Complaint message "${messageId.payload.text}" saved to Firebase`);
            } catch (error) {
                console.error(`Error saving message "${messageId.payload.text}" to Firebase:`, error);
            }
        } else {
            console.log(`Message "${messageId.payload.text}" is not a complaint and was not saved.`);
        }
    };

    useEffect(() => {
        fetchConversation();
        fetchConversations();
        fetchMessages();
        fetchMessage();

    }, []);

    return (
        <div className={`chat-page-container ${toggleDarkMode ? 'background-dark' : 'background-light'}`}>
            <OffcanvasExample toggleDarkMode={toggleDarkMode} toggleDarkTheme={toggleDarkTheme} />

            <div
                className={`chatbot__container ${toggleDarkMode ? 'background-dark' : 'background-light'}`}
                data-message-id={messageId}
                data-conversation-id={conversationId}
            >
                <WebchatProvider key={toggleDarkMode ? 'dark' : 'light'} client={client} configuration={configuration}>
                    <div style={{ height: '620px', overflowY: 'auto' }}>
                        <Webchat />
                    </div>
                </WebchatProvider>
            </div>
        </div>
    );
};

export default Chatbot;


// 12-3-2025
// import React, { useEffect } from 'react';
// import { collection, addDoc } from 'firebase/firestore';
// import { db } from '../../firebase';
// import { Webchat, WebchatProvider, getClient } from '@botpress/webchat';
// import OffcanvasExample from "../Main/NavBar";
// import '../../styles/ChatPage.css';

// // feedback bot
// const clientId = "44c7ac14-f9ea-4bf1-a370-69f904f4a269";
// // const clientId = "3ad3e1b6-ab58-4503-a8ba-ad18bf3d1319";

// const Chatbot = ({ toggleDarkMode, toggleDarkTheme, messageId, conversationId }) => {
//     const client = getClient({ clientId });

//     const configuration = {
//         color: '#044FB0',
//         botName: "LibyaShop",
//         clientId: "3ad3e1b6-ab58-4503-a8ba-ad18bf3d1319",
//         hostUrl: 'https://webchat.botpress.cloud',
//         avatarUrl: "https://files.bpcontent.cloud/2025/03/04/20/20250304204100-W3W60U1N.jpeg",
//         phoneNumber: "091-898-7171",
//         termsConditions: "https://libyashop.ly/terms",
//         privacyPolicy: "https://libyashop.ly/",
//         emailAddress: "@support.com",
//         website: "https://libyashop.ly/",
//         useSessionStorage: true,
//         showBotInfoPage: true,
//         enableConversationDeletion: true,
//         showPoweredBy: false,
//         theme: toggleDarkMode ? 'dark' : 'light',
//         themeColor: toggleDarkMode ? '#1a1a1a' : '#ffffff',
//         hideWidget: true,
//         disableAnimations: true,
//     };

//     const fetchConversations = async () => {
//         const url = 'https://api.botpress.cloud/v1/chat/conversations';
//         const headers = {
//             'x-integration-id': 'intver_01JBVV0VQW8XN3Z5V9K8DB1M3B',
//             // 'x-bot-id': 'f9ae5526-792c-40c1-b41c-05a353d1a9a0',
//             // 'x-bot-id': '9f2c204a-f378-4305-a643-279ca728450f',

//             // feedback
//             'x-bot-id': ' c961d23b-b6bb-4858-805b-8ddb8b117c8e ',

//             'Authorization': 'Bearer bp_pat_pgU4lVEDC6NM5SWCSZOJq1WIzgdHxQ8VC1Yj',
//         };

//         try {
//             const response = await fetch(url, { headers });
//             const data = await response.json();
//             console.log('API Response:', JSON.stringify(data, null, 2));

//             if (data.conversations && Array.isArray(data.conversations)) {
//                 for (const conversation of data.conversations) {
//                     await fetchMessages(conversation.id);
//                 }
//             } else {
//                 console.error('No conversations found in the response or data is not an array');
//             }
//         } catch (error) {
//             console.error('Error fetching conversations:', error);
//         }
//     };

//     const fetchMessages = async (conversationId) => {
//         const url = `https://api.botpress.cloud/v1/chat/messages`;
//         const headers = {
//             'x-integration-id': 'intver_01JBVV0VQW8XN3Z5V9K8DB1M3B',
//             // 'x-bot-id': 'f9ae5526-792c-40c1-b41c-05a353d1a9a0',
//             // 'x-bot-id': '9f2c204a-f378-4305-a643-279ca728450f',

//                         // feedback
//                         'x-bot-id': ' c961d23b-b6bb-4858-805b-8ddb8b117c8e ',
//             'Authorization': 'Bearer bp_pat_pgU4lVEDC6NM5SWCSZOJq1WIzgdHxQ8VC1Yj',
//         };

//         try {
//             const response = await fetch(url, { headers });
//             const data = await response.json();
//             console.log(`Messages for conversation ${conversationId}:`, JSON.stringify(data.messages, null, 2));

//             if (data.messages && Array.isArray(data.messages)) {
//                 for (const message of data.messages) {
//                     await saveMessageToFirebase(conversationId, message);
//                 }
//             } else {
//                 console.error('No messages found in the response or data is not an array');
//             }
//         } catch (error) {
//             console.error(`Error fetching messages for conversation ${conversationId}:`, error);
//         }
//     };

//     const saveMessageToFirebase = async (conversationId, message) => {

//         const complaintKeywords = [
//             'complain', 'issue', 'problem', 'error', 'bad', 'not working', 'poor',
//             'مشكلة', 'حذف', 'شكوي', 'لا يعمل', 'سئ', 'خطأ',
//         ];

//         if (!message || !message.payload || !message.payload.text) {
//             console.error('Invalid message object:', message);
//             return;
//         }

//         const isComplaint = complaintKeywords.some(keyword =>
//             message.payload.text.toLowerCase().includes(keyword)
//         );

//         console.log(`Is complaint: ${isComplaint}`);

//         if (isComplaint) {
//             try {
//                 await addDoc(collection(db, 'Chatbot_Messages'), {
//                     conversationId: conversationId,
//                     messageId: message.id,
//                     text: message.payload.text,
//                     timestamp: message.createdAt,
//                     direction: message.direction,
//                     userId: message.userId,
//                     type: message.type,
//                 });

//                 console.log(`Complaint message "${message.payload.text}" saved to Firebase`);
//             } catch (error) {
//                 console.error(`Error saving message "${message.payload.text}" to Firebase:`, error);
//             }
//         } else {
//             console.log(`Message "${message.payload.text}" is not a complaint and was not saved.`);
//         }
//     };

//     useEffect(() => {
//         // fetchConversation();
//         fetchConversations();
//         fetchMessages();
//         // fetchMessage();

//     });

//     return (
//         <div className={`chat-page-container ${toggleDarkMode ? 'background-dark' : 'background-light'}`}>
//             <OffcanvasExample toggleDarkMode={toggleDarkMode} toggleDarkTheme={toggleDarkTheme} />
//             <div className={`chatbot__container ${toggleDarkMode ? 'background-dark' : 'background-light'}`}>
//                 <WebchatProvider key={toggleDarkMode ? 'dark' : 'light'} client={client} configuration={configuration}>
//                     <div style={{ height: '620px', overflowY: 'auto' }}>
//                         <Webchat />
//                     </div>
//                 </WebchatProvider>
//             </div>
//         </div>
//     );
// };

// export default Chatbot;


// // 13-2-2025
// import React, { useEffect } from 'react';
// import { collection, addDoc } from 'firebase/firestore';
// import { db } from '../../firebase';
// import { Webchat, WebchatProvider, getClient } from '@botpress/webchat';
// import OffcanvasExample from "../Main/NavBar";
// import '../../styles/ChatPage.css';

// const clientId = "44c7ac14-f9ea-4bf1-a370-69f904f4a269";

// const Chatbot = ({ toggleDarkMode, toggleDarkTheme, messageId, conversationId }) => {
//     const client = getClient({ clientId });

//     const configuration = {
//         color: '#044FB0',
//         botName: "LibyaShop",
//         clientId: "3ad3e1b6-ab58-4503-a8ba-ad18bf3d1319",
//         hostUrl: 'https://webchat.botpress.cloud',
//         avatarUrl: "https://files.bpcontent.cloud/2025/03/04/20/20250304204100-W3W60U1N.jpeg",
//         phoneNumber: "091-898-7171",
//         termsConditions: "https://libyashop.ly/terms",
//         privacyPolicy: "https://libyashop.ly/",
//         emailAddress: "@support.com",
//         website: "https://libyashop.ly/",
//         useSessionStorage: true,
//         showBotInfoPage: true,
//         enableConversationDeletion: true,
//         showPoweredBy: false,
//         theme: toggleDarkMode ? 'dark' : 'light',
//         themeColor: toggleDarkMode ? '#1a1a1a' : '#ffffff',
//         hideWidget: true,
//         disableAnimations: true,
//     };

//     const fetchConversations = async () => {
//         const url = 'https://api.botpress.cloud/v1/chat/conversations';
//         const headers = {
//             'x-integration-id': 'intver_01JBVV0VQW8XN3Z5V9K8DB1M3B',
//             'x-bot-id': 'c961d23b-b6bb-4858-805b-8ddb8b117c8e',
//             'Authorization': 'Bearer bp_pat_pgU4lVEDC6NM5SWCSZOJq1WIzgdHxQ8VC1Yj',
//         };

//         try {
//             const response = await fetch(url, { headers });
//             const data = await response.json();
//             console.log('API Response:', JSON.stringify(data, null, 2));

//             if (data.conversations && Array.isArray(data.conversations)) {
//                 for (const conversation of data.conversations) {
//                     await fetchMessages(conversation.id);
//                 }
//             } else {
//                 console.error('No conversations found in the response or data is not an array');
//             }
//         } catch (error) {
//             console.error('Error fetching conversations:', error);
//         }
//     };


//     const fetchMessages = async (conversationId) => {
//         const url = `https://api.botpress.cloud/v1/chat/messages`;
//         const headers = {
//             'x-integration-id': 'intver_01JBVV0VQW8XN3Z5V9K8DB1M3B',
//             'x-bot-id': 'c961d23b-b6bb-4858-805b-8ddb8b117c8e',
//             'Authorization': 'Bearer bp_pat_pgU4lVEDC6NM5SWCSZOJq1WIzgdHxQ8VC1Yj',
//         };

//         try {
//             const response = await fetch(url, { headers });
//             const data = await response.json();
//             console.log(`Messages for conversation ${conversationId}:`, JSON.stringify(data.messages, null, 2));

//             if (data.messages && Array.isArray(data.messages)) {
//                 for (const message of data.messages) {
//                     await saveMessageToFirebase(conversationId, message);
//                 }
//             } else {
//                 console.error('No messages found in the response or data is not an array');
//             }
//         } catch (error) {
//             console.error(`Error fetching messages for conversation ${conversationId}:`, error);
//         }
//     };

//     // const saveMessageToFirebase = async (conversationId, message) => {
//     //     if (!message || !message.payload || !message.payload.text) {
//     //         console.error('Invalid message object:', message);
//     //         return;
//     //     }

//     //     // Check if the conversation is a complaint
//     //     const isComplaint = message.context?.isComplaint === true;

//     //     console.log('isComplaint:', isComplaint);
//     //     console.log('message.context' , message.context);

//     //     if (isComplaint) {
//     //         try {
//     //             await addDoc(collection(db, 'Chatbot_Messages'), {
//     //                 conversationId: conversationId,
//     //                 messageId: message.id,
//     //                 text: message.payload.text,
//     //                 timestamp: message.createdAt,
//     //                 direction: message.direction,
//     //                 userId: message.userId,
//     //                 type: message.type,
//     //             });

//     //             console.log(`Complaint message "${message.payload.text}" saved to Firebase successfully`);
//     //         } catch (error) {
//     //             console.error(`Error saving message "${message.payload.text}" to Firebase:`, error);
//     //         }
//     //     } else {
//     //         console.log(`Message "${message.payload.text}" is not a complaint and was not saved--failed.`);
//     //     }
//     // };


//     const saveMessageToFirebase = async (conversationId, message) => {
//         if (!message || !message.payload || !message.payload.text) {
//             console.error('Invalid message object:', message);
//             return;
//         }
    
//         // Check if the message is a complaint using the isComplaint variable from Botpress
//         const isComplaint = message.context?.isComplaint === true;
    
//         console.log('isComplaint:', isComplaint);
//         console.log('message.context', message.context);
    
//         if (isComplaint) {
//             try {
//                 await addDoc(collection(db, 'Chatbot_Messages'), {
//                     conversationId: conversationId,
//                     messageId: message.id,
//                     text: message.payload.text,
//                     timestamp: message.createdAt,
//                     direction: message.direction,
//                     userId: message.userId,
//                     type: message.type,
//                 });
    
//                 console.log(`Complaint message "${message.payload.text}" saved to Firebase successfully`);
//             } catch (error) {
//                 console.error(`Error saving message "${message.payload.text}" to Firebase:`, error);
//             }
//         } else {
//             console.log(`Message "${message.payload.text}" is not a complaint and was not saved--failed.`);
//         }
//     };

//     useEffect(() => {
//         fetchConversations();
//     }, []);

//     return (
//         <div className={`chat-page-container ${toggleDarkMode ? 'background-dark' : 'background-light'}`}>
//             <OffcanvasExample toggleDarkMode={toggleDarkMode} toggleDarkTheme={toggleDarkTheme} />
//             <div className={`chatbot__container ${toggleDarkMode ? 'background-dark' : 'background-light'}`}>
//                 <WebchatProvider key={toggleDarkMode ? 'dark' : 'light'} client={client} configuration={configuration}>
//                     <div style={{ height: '620px', overflowY: 'auto' }}>
//                         <Webchat />
//                     </div>
//                 </WebchatProvider>
//             </div>
//         </div>
//     );
// };

// export default Chatbot;


// import React, { useEffect } from 'react';
// import { collection, addDoc } from 'firebase/firestore';
// import { db } from '../../firebase';
// import { Webchat, WebchatProvider, getClient } from '@botpress/webchat';
// import OffcanvasExample from "../Main/NavBar";
// import '../../styles/ChatPage.css';

// const clientId = "44c7ac14-f9ea-4bf1-a370-69f904f4a269";

// const Chatbot = ({ toggleDarkMode, toggleDarkTheme }) => {
//     const client = getClient({ clientId });

//     const configuration = {
//         color: '#044FB0',
//         botName: "LibyaShop",
//         clientId: "3ad3e1b6-ab58-4503-a8ba-ad18bf3d1319",
//         hostUrl: 'https://webchat.botpress.cloud',
//         avatarUrl: "https://files.bpcontent.cloud/2025/03/04/20/20250304204100-W3W60U1N.jpeg",
//         phoneNumber: "091-898-7171",
//         termsConditions: "https://libyashop.ly/terms",
//         privacyPolicy: "https://libyashop.ly/",
//         emailAddress: "@support.com",
//         website: "https://libyashop.ly/",
//         useSessionStorage: true,
//         showBotInfoPage: true,
//         enableConversationDeletion: true,
//         showPoweredBy: false,
//         theme: toggleDarkMode ? 'dark' : 'light',
//         themeColor: toggleDarkMode ? '#1a1a1a' : '#ffffff',
//         hideWidget: true,
//         disableAnimations: true,
//     };

//     const fetchConversations = async () => {
//         const url = 'https://api.botpress.cloud/v1/chat/conversations';
//         const headers = {
//             'x-integration-id': 'intver_01JBVV0VQW8XN3Z5V9K8DB1M3B',
//             'x-bot-id': 'c961d23b-b6bb-4858-805b-8ddb8b117c8e',
//             'Authorization': 'Bearer bp_pat_pgU4lVEDC6NM5SWCSZOJq1WIzgdHxQ8VC1Yj',
//         };

//         try {
//             const response = await fetch(url, { headers });
//             const data = await response.json();
//             console.log('API Response:', JSON.stringify(data, null, 2));

//             if (data.conversations && Array.isArray(data.conversations)) {
//                 for (const conversation of data.conversations) {
//                     await fetchMessages(conversation.id);
//                 }
//             } else {
//                 console.error('No conversations found in the response or data is not an array');
//             }
//         } catch (error) {
//             console.error('Error fetching conversations:', error);
//         }
//     };

//     const fetchMessages = async (conversationId) => {
//         const url = `https://api.botpress.cloud/v1/chat/messages`;
//         const headers = {
//             'x-integration-id': 'intver_01JBVV0VQW8XN3Z5V9K8DB1M3B',
//             'x-bot-id': 'c961d23b-b6bb-4858-805b-8ddb8b117c8e',
//             'Authorization': 'Bearer bp_pat_pgU4lVEDC6NM5SWCSZOJq1WIzgdHxQ8VC1Yj',
//         };

//         try {
//             const response = await fetch(url, { headers });
//             const data = await response.json();
//             console.log(`Messages for conversation ${conversationId}:`, JSON.stringify(data.messages, null, 2));

//             if (data.messages && Array.isArray(data.messages)) {
//                 for (const message of data.messages) {
//                     await saveMessageToFirebase(conversationId, message);
//                 }
//             } else {
//                 console.error('No messages found in the response or data is not an array');
//             }
//         } catch (error) {
//             console.error(`Error fetching messages for conversation ${conversationId}:`, error);
//         }
//     };

//     const saveMessageToFirebase = async (conversationId, message) => {
//         if (!message || !message.payload || !message.payload.text) {
//             console.error('Invalid message object:', message);
//             return;
//         }

//         // Check if the message is a complaint using the isComplaint variable from Botpress
//         const isComplaint = message.context?.isComplaint === true;

//         console.log('isComplaint:', isComplaint);
//         console.log('message.context', message.context);

//         if (isComplaint) {
//             try {

//                 await addDoc(collection(db, 'Chatbot_Messages'), {
//                     conversationId: conversationId,
//                     messageId: message.id,
//                     text: message.payload.text,
//                     context: message.payload.context,
//                     timestamp: message.createdAt,
//                     direction: message.direction,
//                     userId: message.userId,
//                     type: message.type,
//                 });

//                 console.log(`Complaint message "${message.payload.text}" saved to Firebase successfully`);
//             } catch (error) {
//                 console.error(`Error saving message "${message.payload.text}" to Firebase:`, error);
//             }
//         } else {
//             console.log(`Message "${message.payload.text}" is not a complaint and was not saved.`);
//             console.log({
//                 conversationId: conversationId,
//                 messageId: message.id,
//                 text: message.payload.text,
//                 context: message.payload.context,
//                 timestamp: message.createdAt,
//                 direction: message.direction,
//                 userId: message.userId,
//                 type: message.type,
//             });
//         }
//     };

//     useEffect(() => {
//         fetchConversations();
//     }, []);

//     return (
//         <div className={`chat-page-container ${toggleDarkMode ? 'background-dark' : 'background-light'}`}>
//             <OffcanvasExample toggleDarkMode={toggleDarkMode} toggleDarkTheme={toggleDarkTheme} />
//             <div className={`chatbot__container ${toggleDarkMode ? 'background-dark' : 'background-light'}`}>
//                 <WebchatProvider key={toggleDarkMode ? 'dark' : 'light'} client={client} configuration={configuration}>
//                     <div style={{ height: '620px', overflowY: 'auto' }}>
//                         <Webchat />
//                     </div>
//                 </WebchatProvider>
//             </div>
//         </div>
//     );
// };

// export default Chatbot;

// 15-3-2025
// import React, { useEffect } from 'react';
// import { collection, addDoc } from 'firebase/firestore';
// import { db } from '../../firebase';
// import { Webchat, WebchatProvider, getClient } from '@botpress/webchat';
// import OffcanvasExample from "../Main/NavBar";
// import '../../styles/ChatPage.css';

// const clientId = "44c7ac14-f9ea-4bf1-a370-69f904f4a269";

// const Chatbot = ({ toggleDarkMode, toggleDarkTheme }) => {
//     const client = getClient({ clientId });

//     const configuration = {
//         color: '#044FB0',
//         botName: "LibyaShop",
//         clientId: "3ad3e1b6-ab58-4503-a8ba-ad18bf3d1319",
//         hostUrl: 'https://webchat.botpress.cloud',
//         avatarUrl: "https://files.bpcontent.cloud/2025/03/04/20/20250304204100-W3W60U1N.jpeg",
//         phoneNumber: "091-898-7171",
//         termsConditions: "https://libyashop.ly/terms",
//         privacyPolicy: "https://libyashop.ly/",
//         emailAddress: "@support.com",
//         website: "https://libyashop.ly/",
//         useSessionStorage: true,
//         showBotInfoPage: true,
//         enableConversationDeletion: true,
//         showPoweredBy: false,
//         theme: toggleDarkMode ? 'dark' : 'light',
//         themeColor: toggleDarkMode ? '#1a1a1a' : '#ffffff',
//         hideWidget: true,
//         disableAnimations: true,
//     };

//     const fetchConversations = async () => {
//         const url = 'https://api.botpress.cloud/v1/chat/conversations';
//         const headers = {
//             'x-integration-id': 'intver_01JBVV0VQW8XN3Z5V9K8DB1M3B',
//             'x-bot-id': 'c961d23b-b6bb-4858-805b-8ddb8b117c8e',
//             'Authorization': 'Bearer bp_pat_pgU4lVEDC6NM5SWCSZOJq1WIzgdHxQ8VC1Yj',
//         };

//         try {
//             const response = await fetch(url, { headers });
//             const data = await response.json();
//             console.log('API Response:', JSON.stringify(data, null, 2));

//             if (data.conversations && Array.isArray(data.conversations)) {
//                 for (const conversation of data.conversations) {
//                     await fetchMessages(conversation.id);
//                 }
//             } else {
//                 console.error('No conversations found in the response or data is not an array');
//             }
//         } catch (error) {
//             console.error('Error fetching conversations:', error);
//         }
//     };

//     const fetchMessages = async (conversationId) => {
//         const url = `https://api.botpress.cloud/v1/chat/messages`;
//         const headers = {
//             'x-integration-id': 'intver_01JBVV0VQW8XN3Z5V9K8DB1M3B',
//             'x-bot-id': 'c961d23b-b6bb-4858-805b-8ddb8b117c8e',
//             'Authorization': 'Bearer bp_pat_pgU4lVEDC6NM5SWCSZOJq1WIzgdHxQ8VC1Yj',
//         };

//         try {
//             const response = await fetch(url, { headers });
//             const data = await response.json();
//             console.log(`Messages for conversation ${conversationId}:`, JSON.stringify(data.messages, null, 2));

            // if (data.messages && Array.isArray(data.messages)) {
            //     for (const message of data.messages) {
            //         await saveMessageToFirebase(conversationId, message);
            //     }
            // } else {
            //     console.error('No messages found in the response or data is not an array');
            // }
//         } catch (error) {
//             console.error(`Error fetching messages for conversation ${conversationId}:`, error);
//         }
//     };

//     const saveMessageToFirebase = async (conversationId, message) => {
//         if (!message || !message.payload || !message.payload.text) {
//             console.error('Invalid message object:', message);
//             return;
//         }

//         // Check if the message is a complaint using the isComplaint variable from Botpress
//         const isComplaint = message.context?.isComplaint === true;

//         console.log('isComplaint:', isComplaint);
//         console.log('message.context', message.context);

//         if (isComplaint) {
//             try {
//                 await addDoc(collection(db, 'Chatbot_Messages'), {
//                     conversationId: conversationId,
//                     messageId: message.id,
//                     text: message.payload.text,
//                     context: message.payload.context,
//                     timestamp: message.createdAt,
//                     direction: message.direction,
//                     userId: message.userId,
//                     type: message.type,
//                 });

//                 console.log(`Complaint message "${message.payload.text}" saved to Firebase successfully`);
//             } catch (error) {
//                 console.error(`Error saving message "${message.payload.text}" to Firebase:`, error);
//             }
//         } else {
//             console.log(`Message "${message.payload.text}" is not a complaint and was not saved.`);
//             console.log({
//                 conversationId: conversationId,
//                 messageId: message.id,
//                 text: message.payload.text,
//                 context: message.payload.context,
//                 timestamp: message.createdAt,
//                 direction: message.direction,
//                 userId: message.userId,
//                 type: message.type,
//             });
//         }
//     };

//     useEffect(() => {
//         const handleEvent = (event) => {
//             if (event.type === 'isComplaint' && event.payload.isComplaint === true) {
//                 fetchConversations();
//             }
//         };

//         client.on('event', handleEvent);

//         return () => {
//             client.off('event', handleEvent);
//         };
//     }, [client]);

//     return (
//         <div className={`chat-page-container ${toggleDarkMode ? 'background-dark' : 'background-light'}`}>
//             <OffcanvasExample toggleDarkMode={toggleDarkMode} toggleDarkTheme={toggleDarkTheme} />
//             <div className={`chatbot__container ${toggleDarkMode ? 'background-dark' : 'background-light'}`}>
//                 <WebchatProvider key={toggleDarkMode ? 'dark' : 'light'} client={client} configuration={configuration}>
//                     <div style={{ height: '620px', overflowY: 'auto' }} aria-describedby="chat-description">
//                         <p id="chat-description" style={{ display: 'none' }}>
//                             This is a chat interface for interacting with the LibyaShop chatbot.
//                         </p>
//                         <Webchat />
//                     </div>
//                 </WebchatProvider>
//             </div>
//         </div>
//     );
// };

// export default Chatbot;


// import React, { useEffect } from 'react';
// import { collection, addDoc } from 'firebase/firestore';
// import { db } from '../../firebase';
// import { Webchat, WebchatProvider, getClient } from '@botpress/webchat';
// // import { v4 as uuidv4 } from 'uuid';
// import OffcanvasExample from "../Main/NavBar";
// import '../../styles/ChatPage.css';

// // const clientId = "3ad3e1b6-ab58-4503-a8ba-ad18bf3d1319";
// const clientId = "44c7ac14-f9ea-4bf1-a370-69f904f4a269";
// const Chatbot = ({ toggleDarkMode, toggleDarkTheme }) => {
//     const client = getClient({ clientId });

//     const configuration = {
//         color: '#044FB0',
//         botName: "LibyaShop",
//         clientId: "3ad3e1b6-ab58-4503-a8ba-ad18bf3d1319",
//         hostUrl: 'https://webchat.botpress.cloud',
//         avatarUrl: "https://files.bpcontent.cloud/2025/03/04/20/20250304204100-W3W60U1N.jpeg",
//         phoneNumber: "091-898-7171",
//         termsConditions: "https://libyashop.ly/terms",
//         privacyPolicy: "https://libyashop.ly/",
//         emailAddress: "@support.com",
//         website: "https://libyashop.ly/",
//         useSessionStorage: true,
//         showBotInfoPage: true,
//         enableConversationDeletion: true,
//         showPoweredBy: false,
//         theme: toggleDarkMode ? 'dark' : 'light',
//         themeColor: toggleDarkMode ? '#1a1a1a' : '#ffffff',
//         hideWidget: true,
//         disableAnimations: true,
//     };

//     // GET A CONVERSATION
//     const fetchConversation = async () => {
//         const url = 'https://api.botpress.cloud/v1/chat/conversations';
//         const headers = {
//             'x-integration-id': 'intver_01JBVV0VQW8XN3Z5V9K8DB1M3B',
//             'x-bot-id': '9f2c204a-f378-4305-a643-279ca728450f',
//             'Authorization': 'Bearer bp_pat_pgU4lVEDC6NM5SWCSZOJq1WIzgdHxQ8VC1Yj',
//         };

//         try {
//             const response = await fetch(url, { headers });
//             const data = await response.json();
//             if (data.conversations && Array.isArray(data.conversations)) {
//                 for (const conversation of data.conversations) {
//                     await fetchMessages(conversation.id);
//                 }
//             } else {
//                 console.error('No conversations found in the response or data is not an array');
//             }
//         } catch (error) {
//             console.error('Error fetching conversations:', error);
//         }
//     };

//     // GET ALL Conversations
//     const fetchConversations = async () => {
//         const url = 'https://api.botpress.cloud/v1/chat/conversations';
//         const headers = {
//             'x-integration-id': 'intver_01JBVV0VQW8XN3Z5V9K8DB1M3B',
//             'x-bot-id': 'c961d23b-b6bb-4858-805b-8ddb8b117c8e',
//             'Authorization': 'Bearer bp_pat_pgU4lVEDC6NM5SWCSZOJq1WIzgdHxQ8VC1Yj',
//         };

//         try {
//             const response = await fetch(url, { headers });
//             const data = await response.json();
//             if (data.conversations && Array.isArray(data.conversations)) {
//                 for (const conversation of data.conversations) {
//                     await fetchMessages(conversation.id);
//                 }
//             } else {
//                 console.error('No conversations found in the response or data is not an array');
//             }
//         } catch (error) {
//             console.error('Error fetching conversations:', error);
//         }
//     };

//     // GET A MESSAGE 
//     const fetchMessage = async (messageId) => {
//         const url = `https://api.botpress.cloud/v1/chat/messages/${messageId}`;
//         const headers = {
//             'x-integration-id': 'intver_01JBVV0VQW8XN3Z5V9K8DB1M3B',
//             'x-bot-id': 'c961d23b-b6bb-4858-805b-8ddb8b117c8e',
//             'Authorization': 'Bearer bp_pat_pgU4lVEDC6NM5SWCSZOJq1WIzgdHxQ8VC1Yj',
//         };

//         try {
//             const response = await fetch(url, { headers });
//             const data = await response.json();
//             if (data.messages && Array.isArray(data.messages)) {
//                 for (const message of data.messages) {
//                     await saveMessageToFirebase(message.conversationId, message.id, message);
//                 }
//             }
//         } catch (error) {
//             console.error(`Error fetching message ${messageId}:`, error);
//         }
//     };

//     // GET ALL MESSAGES
//     const fetchMessages = async (conversationId) => {
//         const url = `https://api.botpress.cloud/v1/chat/messages`;
//         const headers = {
//             'x-integration-id': 'intver_01JBVV0VQW8XN3Z5V9K8DB1M3B',
//             'x-bot-id': 'c961d23b-b6bb-4858-805b-8ddb8b117c8e',
//             'Authorization': 'Bearer bp_pat_pgU4lVEDC6NM5SWCSZOJq1WIzgdHxQ8VC1Yj',
//         };

//         try {
//             const response = await fetch(url, { headers });
//             const data = await response.json();
//             if (data.messages && Array.isArray(data.messages)) {
//                 for (const message of data.messages) {
//                     await saveMessageToFirebase(conversationId, message.id, message);
//                 }
//             } else {
//                 console.error('No messages found in the response or data is not an array');
//             }
//         } catch (error) {
//             console.error(`Error fetching messages for conversation ${conversationId}:`, error);
//         }
//     };

//     const saveMessageToFirebase = async (conversationId, messageId, message) => {
//         if (!message || !message.payload || !message.payload.text) {
//             console.error('Invalid message object:', message);
//             return;
//         }

//         // Check if the message is a complaint
//         const isComplaint = message.context?.isComplaint === true;

//         if (isComplaint) {
//             try {
//                 await addDoc(collection(db, 'Chatbot_Messages22'), {
//                     conversationId: conversationId,
//                     messageId: messageId,
//                     text: message.payload.text,
//                     timestamp: message.createdAt,
//                     direction: message.direction,
//                     userId: message.userId,
//                     type: message.type,
//                 });

//                 console.log(`Complaint message "${message.payload.text}" saved to Firebase`);
//             } catch (error) {
//                 console.error(`Error saving message "${message.payload.text}" to Firebase:`, error);
//             }
//         } else {
//             console.log(`Message "${message.payload.text}" is not a complaint and was not saved.`);
//         }
//     };

//     useEffect(() => {
//         fetchConversation();
//         fetchConversations();
//     }, []);

//     return (
//         <div className={`chat-page-container ${toggleDarkMode ? 'background-dark' : 'background-light'}`}>
//             <OffcanvasExample toggleDarkMode={toggleDarkMode} toggleDarkTheme={toggleDarkTheme} />
//             <div className={`chatbot__container ${toggleDarkMode ? 'background-dark' : 'background-light'}`}>
//                 <WebchatProvider key={toggleDarkMode ? 'dark' : 'light'} client={client} configuration={configuration}>
//                     <div style={{ height: '620px', overflowY: 'auto' }}>
//                         <Webchat />
//                     </div>
//                 </WebchatProvider>
//             </div>
//         </div>
//     );
// };

// export default Chatbot;
// import React, { useEffect } from 'react';
// import { collection, addDoc } from 'firebase/firestore';
// import { db } from '../../firebase';
// import { Webchat, WebchatProvider, getClient } from '@botpress/webchat';
// import OffcanvasExample from "../Main/NavBar";
// import '../../styles/ChatPage.css';

// const clientId = "44c7ac14-f9ea-4bf1-a370-69f904f4a269";

// const Chatbot = ({ toggleDarkMode, toggleDarkTheme }) => {
//     const client = getClient({ clientId });

//     const configuration = {
//         color: '#044FB0',
//         botName: "LibyaShop",
//         clientId: "3ad3e1b6-ab58-4503-a8ba-ad18bf3d1319",
//         hostUrl: 'https://webchat.botpress.cloud',
//         avatarUrl: "https://files.bpcontent.cloud/2025/03/04/20/20250304204100-W3W60U1N.jpeg",
//         phoneNumber: "091-898-7171",
//         termsConditions: "https://libyashop.ly/terms",
//         privacyPolicy: "https://libyashop.ly/",
//         emailAddress: "@support.com",
//         website: "https://libyashop.ly/",
//         useSessionStorage: true,
//         showBotInfoPage: true,
//         enableConversationDeletion: true,
//         showPoweredBy: false,
//         theme: toggleDarkMode ? 'dark' : 'light',
//         themeColor: toggleDarkMode ? '#1a1a1a' : '#ffffff',
//         hideWidget: true,
//         disableAnimations: true,
//     };

//     // GET ALL Conversations
//     const fetchConversations = async () => {
//         const url = 'https://api.botpress.cloud/v1/chat/conversations';
//         const headers = {
//             'x-integration-id': 'intver_01JBVV0VQW8XN3Z5V9K8DB1M3B',
//             'x-bot-id': 'c961d23b-b6bb-4858-805b-8ddb8b117c8e',
//             'Authorization': 'Bearer bp_pat_pgU4lVEDC6NM5SWCSZOJq1WIzgdHxQ8VC1Yj',
//         };

//         try {
//             const response = await fetch(url, { headers });
//             const data = await response.json();
//             if (data.conversations && Array.isArray(data.conversations)) {
//                 for (const conversation of data.conversations) {
//                     await fetchMessages(conversation.id);
//                 }
//             } else {
//                 console.error('No conversations found in the response or data is not an array');
//             }
//         } catch (error) {
//             console.error('Error fetching conversations:', error);
//         }
//     };

//     // GET ALL MESSAGES
//     const fetchMessages = async (conversationId) => {
//         const url = `https://api.botpress.cloud/v1/chat/messages`;
//         const headers = {
//             'x-integration-id': 'intver_01JBVV0VQW8XN3Z5V9K8DB1M3B',
//             'x-bot-id': 'c961d23b-b6bb-4858-805b-8ddb8b117c8e',
//             'Authorization': 'Bearer bp_pat_pgU4lVEDC6NM5SWCSZOJq1WIzgdHxQ8VC1Yj',
//         };

//         try {
//             const response = await fetch(url, { headers });
//             const data = await response.json();
//             if (data.messages && Array.isArray(data.messages)) {
//                 for (const message of data.messages) {
//                     console.log('Full Message Object:', message); // Log the entire message
//                     await saveMessageToFirebase(conversationId, message.id, message);
//                 }
//             } else {
//                 console.error('No messages found in the response or data is not an array');
//             }
//         } catch (error) {
//             console.error(`Error fetching messages for conversation ${conversationId}:`, error);
//         }
//     };

//     const saveMessageToFirebase = async (conversationId, messageId, message) => {
//         if (!message || !message.payload) {
//             console.error('Invalid message object:', message);
//             return;
//         }

//         // Skip non-text messages (e.g., images, buttons, etc.)
//         if (!message.payload.text) {
//             console.log('Skipping non-text message:', message);
//             return;
//         }

//         // Provide a default empty object for context if it's missing
//         const context = message.context || {};

//         // Log the entire message context to verify isComplaint
//         console.log('Message Context:', context);

//         // Check if the message is a complaint
//         const isComplaint = context.isComplaint === true;

//         if (isComplaint) {
//             console.log('Saving complaint message to Firebase:', message.payload.text);

//             try {
//                 const docRef = await addDoc(collection(db, 'Chatbot_Messages22'), {
//                     conversationId: conversationId,
//                     messageId: messageId,
//                     text: message.payload.text,
//                     timestamp: message.createdAt,
//                     direction: message.direction,
//                     userId: message.userId,
//                     type: message.type,
//                 });

//                 console.log(`Complaint message "${message.payload.text}" saved to Firebase with ID:`, docRef.id);
//             } catch (error) {
//                 console.error(`Error saving message "${message.payload.text}" to Firebase:`, error);
//             }
//         } else {
//             console.log(`Message "${message.payload.text}" is not a complaint and was not saved.`);
//         }
//     };

//     useEffect(() => {
//         fetchConversations();
//     }, []);

//     return (
//         <div className={`chat-page-container ${toggleDarkMode ? 'background-dark' : 'background-light'}`}>
//             <OffcanvasExample toggleDarkMode={toggleDarkMode} toggleDarkTheme={toggleDarkTheme} />
//             <div className={`chatbot__container ${toggleDarkMode ? 'background-dark' : 'background-light'}`}>
//                 <WebchatProvider key={toggleDarkMode ? 'dark' : 'light'} client={client} configuration={configuration}>
//                     <div style={{ height: '620px', overflowY: 'auto' }}>
//                         <Webchat />
//                     </div>
//                 </WebchatProvider>
//             </div>
//         </div>
//     );
// };

// export default Chatbot;

// import React, { useEffect } from 'react';
// import { collection, addDoc } from 'firebase/firestore';
// import { db } from '../../firebase';
// import { Webchat, WebchatProvider, getClient } from '@botpress/webchat';
// import OffcanvasExample from "../Main/NavBar";
// import '../../styles/ChatPage.css';

// const clientId = "686ee783-8e3a-4292-8ad4-22808694d044";

// const Chatbot = ({ toggleDarkMode, toggleDarkTheme }) => {
//     const client = getClient({ clientId });

//     const configuration = {
//         color: '#044FB0',
//         botName: "LibyaShop",
//         clientId: "3ad3e1b6-ab58-4503-a8ba-ad18bf3d1319",
//         hostUrl: 'https://webchat.botpress.cloud',
//         avatarUrl: "https://files.bpcontent.cloud/2025/03/04/20/20250304204100-W3W60U1N.jpeg",
//         phoneNumber: "091-898-7171",
//         termsConditions: "https://libyashop.ly/terms",
//         privacyPolicy: "https://libyashop.ly/",
//         emailAddress: "@support.com",
//         website: "https://libyashop.ly/",
//         useSessionStorage: true,
//         showBotInfoPage: true,
//         enableConversationDeletion: true,
//         showPoweredBy: false,
//         theme: toggleDarkMode ? 'dark' : 'light',
//         themeColor: toggleDarkMode ? '#1a1a1a' : '#ffffff',
//         hideWidget: true,
//         disableAnimations: true,
//     };

//     // Fetch all conversations
//     const fetchConversations = async () => {
//         const url = 'https://api.botpress.cloud/v1/chat/conversations';
//         const headers = {
//             'x-integration-id': 'intver_01JBVV0VQW8XN3Z5V9K8DB1M3B',
//             'x-bot-id': '939ec8f8-aa49-46be-9765-53464beeb5d3',
//             'Authorization': 'Bearer bp_pat_8e38u6b4b05Zs6ac103ZGI9ZbAEn9I369HdK',
//         };

//         try {
//             const response = await fetch(url, { headers });
//             const data = await response.json();
//             if (data.conversations && Array.isArray(data.conversations)) {
//                 for (const conversation of data.conversations) {
//                     await fetchMessages(conversation.id);
//                 }
//             } else {
//                 console.error('No conversations found in the response or data is not an array');
//             }
//         } catch (error) {
//             console.error('Error fetching conversations:', error);
//         }
//     };

//     // Fetch all messages for a conversation
//     const fetchMessages = async (conversationId) => {
//         const url = `https://api.botpress.cloud/v1/chat/messages`;
//         const headers = {
//             'x-integration-id': 'intver_01JBVV0VQW8XN3Z5V9K8DB1M3B',
//             'x-bot-id': '939ec8f8-aa49-46be-9765-53464beeb5d3',
//             'Authorization': 'Bearer bp_pat_8e38u6b4b05Zs6ac103ZGI9ZbAEn9I369HdK',
//         };

//         try {
//             const response = await fetch(url, { headers });
//             const data = await response.json();
//             if (data.messages && Array.isArray(data.messages)) {
//                 for (const message of data.messages) {
//                     console.log('Full Message Object:', message); // Log the entire message
//                     await saveMessageToFirebase(conversationId, message.id, message);

//                     // Fetch the event details if it's a card event
//                     if (message.type === 'card' && message.id) {
//                         await fetchEvent(message.id);
//                     }
//                 }
//             } else {
//                 console.error('No messages found in the response or data is not an array');
//             }
//         } catch (error) {
//             console.error(`Error fetching messages for conversation ${conversationId}:`, error);
//         }
//     };

//     // Save message to Firebase
//     const saveMessageToFirebase = async (conversationId, messageId, message) => {
//         if (!message || !message.payload) {
//             console.error('Invalid message object:', message);
//             return;
//         }

//         // Handle text messages
//         if (message.type === 'text' && message.payload.text) {
//             console.log('Processing Text Message:', message.payload.text);

//             try {
//                 const docRef = await addDoc(collection(db, 'Event_Complaint'), {
//                     conversationId: conversationId,
//                     messageId: messageId,
//                     text: message.payload.text,
//                     timestamp: message.createdAt,
//                     direction: message.direction,
//                     userId: message.userId,
//                     type: message.type,
//                 });

//                 console.log(`Message "${message.payload.text}" saved to Firebase with ID:`, docRef.id);
//             } catch (error) {
//                 console.error(`Error saving message "${message.payload.text}" to Firebase:`, error);
//             }
//             return;
//         }


//         // Skip non-text messages without a payload.text property
//         if (!message.payload.text) {
//             console.log('Skipping non-text message:', message);
//             return;
//         }
//     };

//     // Fetch a specific event by ID
//     const fetchEvent = async (eventId) => {
//         const url = `https://api.botpress.cloud/v1/chat/events/${eventId}`;
//         const headers = {
//             'x-integration-id': 'intver_01JBVV0VQW8XN3Z5V9K8DB1M3B',
//             'x-bot-id': '939ec8f8-aa49-46be-9765-53464beeb5d3',
//             'Authorization': 'Bearer bp_pat_8e38u6b4b05Zs6ac103ZGI9ZbAEn9I369HdK',
//         };

//         try {
//             const response = await fetch(url, { headers });
//             const data = await response.json();
//             console.log('Event Data:', data);

//             // Check if the event is a complaint
//             if (data.payload?.isComplaint === true) {
//                 console.log('This is a complaint event.');
//                 await saveEventToFirebase(data);
//             } else {
//                 console.log('This is not a complaint event.');
//             }
//         } catch (error) {
//             console.error('Error fetching event:', error);
//         }
//     };

//     // Save event to Firebase
//     const saveEventToFirebase = async (event) => {
//         if (!event || !event.payload) {
//             console.error('Invalid event object:', event);
//             return;
//         }

//         try {
//             const docRef = await addDoc(collection(db, 'Event_Complaint'), {
//                 eventId: event.id,
//                 conversationId: event.conversationId,
//                 payload: event.payload,
//                 context: event.context,
//                 timestamp: event.createdAt,
//                 type: event.type,
//             });

//             console.log(`Event "${event.id}" saved to Firebase with ID:`, docRef.id);
//         } catch (error) {
//             console.error(`Error saving event "${event.id}" to Firebase:`, error);
//         }
//     };

//     useEffect(() => {
//         fetchConversations();

//         // Example: Fetch a specific event for testing
//         // const testEventId = 'evt_01JPD4QNJ8NHVRAAZAXY37PS4C'; 
//         // fetchEvent(testEventId);

//         fetchEvent();
//     }, []);

//     return (
//         <div className={`chat-page-container ${toggleDarkMode ? 'background-dark' : 'background-light'}`}>
//             <OffcanvasExample toggleDarkMode={toggleDarkMode} toggleDarkTheme={toggleDarkTheme} />
//             <div className={`chatbot__container ${toggleDarkMode ? 'background-dark' : 'background-light'}`}>
//                 <WebchatProvider key={toggleDarkMode ? 'dark' : 'light'} client={client} configuration={configuration}>
//                     <div style={{ height: '620px', overflowY: 'auto' }}>
//                         <Webchat />
//                     </div>
//                 </WebchatProvider>
//             </div>
//         </div>
//     );
// };

// export default Chatbot;



// latest 22-3
// import React from 'react';
// // import { collection, addDoc } from 'firebase/firestore';
// // import { db } from '../../firebase';
// import { Webchat, WebchatProvider, getClient } from '@botpress/webchat';
// import OffcanvasExample from "../Main/NavBar";
// import '../../styles/ChatPage.css';

// const clientId = "44c7ac14-f9ea-4bf1-a370-69f904f4a269";

// const Chatbot = ({ toggleDarkMode, toggleDarkTheme }) => {
//     const client = getClient({ clientId });

//     const configuration = {
//         color: '#044FB0',
//         botName: "LibyaShop",
//         clientId: "44c7ac14-f9ea-4bf1-a370-69f904f4a269",
//         hostUrl: 'https://webchat.botpress.cloud',
//         avatarUrl: "https://files.bpcontent.cloud/2025/03/04/20/20250304204100-W3W60U1N.jpeg",
//         phoneNumber: "091-898-7171",
//         termsConditions: "https://libyashop.ly/terms",
//         privacyPolicy: "https://libyashop.ly/",
//         emailAddress: "@support.com",
//         website: "https://libyashop.ly/",
//         useSessionStorage: true,
//         showBotInfoPage: true,
//         enableConversationDeletion: true,
//         showPoweredBy: false,
//         themeColor: toggleDarkMode ? '#1a1a1a' : '#ffffff',
//         hideWidget: true,
//         disableAnimations: true,
//         theme: 
//         toggleDarkMode ? 'dark' : 'light',
    
//     };

//     return (
//         <div className={`chat-page-container ${toggleDarkMode ? 'background-dark' : 'background-light'}`}>
//             <OffcanvasExample toggleDarkMode={toggleDarkMode} toggleDarkTheme={toggleDarkTheme} />
//             <div className={`chatbot__container ${toggleDarkMode ? 'background-dark' : 'background-light'}`}>
//                 <WebchatProvider key={toggleDarkMode ? 'dark' : 'light'} client={client} configuration={configuration}>
//                     <div style={{ height: '620px', overflowY: 'auto' }}>
//                         <Webchat />
//                     </div>
//                 </WebchatProvider>
//             </div>
//         </div>
//     );
// };

// export default Chatbot;